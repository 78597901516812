<template>

  <div class="max-w-md md:mx-auto md:max-w-screen-xl w-full justify-center">
      <slot/>
  </div>
</template>


<script setup>
 
 
  </script>